import styled from '@emotion/styled'

export const AlumGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  gap: 16px;
`

export const NavigationSectionHeading = styled.p`
  padding-bottom: 6px;
  font-size: 13px;
`

export const FilterContainer = styled.div`
  padding: var(--p-space-4);

  @media (min-width: 490px) {
    padding: var(--p-space-5);
  }
`

export const CardSubtitle = styled.div`
  padding: var(--p-space-2) var(--p-space-4) 0 var(--p-space-4);

  @media (min-width: 490px) {
    padding-left: var(--p-space-5);
    padding-right: var(--p-space-5);
  }
`

export const CardFooter = styled.div`
  padding: var(--p-space-0) var(--p-space-4) var(--p-space-4) var(--p-space-4);

  @media (min-width: 490px) {
    padding: var(--p-space-0) var(--p-space-5) var(--p-space-5) var(--p-space-5);
  }
`;

export const PageActionContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 48px;
`

export const FloatingSummaryPageContainer = styled.div`
  .Polaris-Layout__Section--oneThird {
    position: sticky;
    top: 74px;
  }
`

export const CheckboxDisclosureContent = styled.div`
  position: relative;
  padding-left: 30px;

  @media (min-width: 768px) {
    padding-left: 28px;
  }

  &:after {
    position: absolute;
    left: 9px;
    top: 16px;
    content: '';
    width: 1px;
    height: calc(100% - 16px);
    background: rgba(0,0,0,0.1);
  }
`

export const TextCenter = styled.div`
  text-align: center;
`

export const TextRight = styled.div`
  text-align: right;
`

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const JustifyRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ProgressBarWrapper = styled.div`
  position: relative;
  padding-bottom: 12px;

  .Polaris-ProgressBar--colorHighlight {
    --pc-progress-bar-indicator: ${(props) => props.progressBarColor};
  }
`

export const ProgressBarNotch = styled.div`
  position: absolute;
  pointer-events: none;

  &:first-of-type, &:last-of-type {
    opacity: 0;
    transform: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    bottom: 100%;
  }
`

export const ProgressBarNotchWrapper = styled.div`
`

export const CounselingThresholdContainer = styled.div`
`

export const CounselingThresholdItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  padding-left: 24px;
`

export const ThresholdColorIndicator = styled.div`
  position: absolute;
  content: '';
  left: 0;
  top: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${(props) => props.color};
  margin-right: 8px;
`

export const styles = {
  body: {
    margin: '1em 2em',
    fontFamily: 'Times, "Times New Roman", serif',
  },
  heading: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: '4px',
  },
  subheading: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: '700',
    marginBottom: '16px',
  },
  hr: {
    height: '6px',
    backgroundColor: '#818000',
    color: '#818000',
    borderWidth: '0',
    margin: '0',
  },
  signatureLine: {
    width: '100%',
    borderBottom: '1px solid #000',
    minHeight: '24px',
  },
  signatureLabelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '6px',
    paddingRight: '48px',
  },
  signatureLabel: {
    fontWeight: '700',
  },
  dateLabel: {
    fontWeight: '700',
    minWidth: '150px',
  },
  label: {
    textTransform: 'uppercase',
  },
  italic: {
    fontStyle: 'italic',
  },
  flexContainer: {
    display: 'flex',
    marginTop: '4px',
    marginBottom: '8px',
  },
  flexItem: {
    flex: '1',
  },
  flexItemContainer: {
    display: 'flex',
    flex: '1',
  },
  actionTakenLabel: {
    alignItems: 'flex-end',
    display: 'flex',
  },
}