import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AppProvider } from '@shopify/polaris'

import { AuthProvider } from '@redwoodjs/auth'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import translations from "@shopify/polaris/locales/en.json";

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './scaffold.css'
import './styles.css'
import './index.css'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  })
}

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <AppProvider i18n={translations}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider type="dbAuth">
          <RedwoodApolloProvider
            graphQLClientConfig={{
              cacheConfig: {
                addTypename: false,
              },
            }}
          >
            <Routes />
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </AppProvider>
  </FatalErrorBoundary>
)

export default App
