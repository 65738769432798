import { Frame, TopBar } from '@shopify/polaris'
import logo from './logo.png'
import { Toaster } from '@redwoodjs/web/toast'

const MainLayout = ({ children }) => {
  return (
    <Frame
      topBar={(
        <TopBar/>
      )}
      logo={{
        topBarSource: logo,
      }}
    >
      {children}
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
    </Frame>
  )
}

export default MainLayout
