import { useState } from 'react'

import { FullscreenBar, Loading, Stack, TopBar } from '@shopify/polaris'
import { LogOutMinor } from '@shopify/polaris-icons'

import { useAuth } from '@redwoodjs/auth'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { initials, userDisplayString } from 'src/helpers/strings'

import { PermissionsProvider } from '../../contexts/permissions'

import { navigate, routes } from '@redwoodjs/router'

const USER_QUERY = gql`
  query OnboardingUserQuery {
    currentUser {
      id
      email
      roles
      phone
      externalId
      firstName
      lastName
      associate {
        id
        firstName
        lastName
        externalId
        name
        costCenters {
          id
          name
        }
      }
    }
  }
`

const BulkEditOccurrencesLayout = ({ loading, user, children }) => {
  const { logOut, currentUser } = useAuth()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const toggleShowUserMenu = () => setShowUserMenu(!showUserMenu)

  const associate = user?.associate

  const userMenuMarkup = !loading && (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Log out',
              icon: LogOutMinor,
              onAction: logOut,
            },
          ],
        },
      ]}
      name={userDisplayString(user)}
      detail={associate?.costCenter?.name}
      initials={initials(user)}
      open={showUserMenu}
      onToggle={toggleShowUserMenu}
    />
  )

  return (
    <div id="BulkEditOccurrencesLayout">
      <FullscreenBar onAction={() => navigate(routes.associates())}>
        <Stack alignment="center" wrap="false">
          <Stack.Item fill>
            <img style={{ cursor: 'pointer' }} className="FullscreenBar__Logo" src={require('./logo.png')} onClick={() => navigate(routes.associates())} />
          </Stack.Item>
        </Stack>
      </FullscreenBar>
      {loading && <Loading />}
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      {children}
    </div>
  )
}

const BulkEditOccurrencesLayoutContainer = ({ children }) => {
  const { loading, data } = useQuery(USER_QUERY)

  const user = data?.currentUser

  if (loading) {
    return ''
  }

  return (
    <PermissionsProvider currentUser={user}>
      <BulkEditOccurrencesLayout loading={loading} user={user}>
        {children}
      </BulkEditOccurrencesLayout>
    </PermissionsProvider>
  )
}

export default BulkEditOccurrencesLayoutContainer
