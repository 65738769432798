import { useState } from 'react'

import styled from '@emotion/styled'
import {
  Badge,
  Frame,
  Loading,
  Navigation,
  ProgressBar,
  Stack,
  Text,
  TopBar,
} from '@shopify/polaris'
import {
  LogOutMinor,
  SettingsMinor,
  CustomersMinor,
  FinancesMinor,
  PasskeyMinor,
  AnalyticsMinor,
  QuestionMarkMinor,
  CircleTickMinor,
  InviteMinor,
  PrintMinor,
} from '@shopify/polaris-icons'

import { useAuth } from '@redwoodjs/auth'
import { navigate, routes, useMatch } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { NavigationSectionHeading } from 'src/components/shared'
import { initials, userDisplayString } from 'src/helpers/strings'

import { usePermissions, PermissionsProvider } from '../../contexts/permissions'

import logo from './logo.png'

const ImportProgressBarContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 515;
  left: 50%;
  top: 4px;
  width: 220px;
  cursor: pointer;
  padding: 6px 8px;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 6px;

  p {
    position: relative;
    top: -2px;
    font-size: 13px;
  }

  @media (min-width: 375px) {
    width: 250px;
  }

  &:hover {
    background-color: var(--p-background-hovered);
  }
`

const USER_QUERY = gql`
  query AppCurrentUserQuery {
    currentUser {
      id
      email
      roles
      firstName
      lastName
      externalId
      phone
      requestedCounselingsCount
      hasCompletedOnboarding
      associate {
        id
        externalId
        firstName
        lastName
        phoneNumber
        name
        costCenters {
          name
        }
      }
    }
  }
`

const PaddedBottom = styled.div`
  height: 100%;
  padding-bottom: 24px;
`

const ExpiringOccurrencesBadge = () => {
  const QUERY = gql`
    query ExpiringOccurrencesBadgeQuery {
      currentUser {
        requestedCounselingsCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const requestedCounselingsCount =
    data?.currentUser?.requestedCounselingsCount || 0

  if (requestedCounselingsCount && requestedCounselingsCount > 0) {
    return <Badge>{requestedCounselingsCount}</Badge>
  } else {
    return ''
  }
}

const AssociateTransfersBadge = () => {
  const QUERY = gql`
    query AssociateTransfersBadgeQuery {
      currentUser {
        associateTransferRequestsCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const associateTransferRequestsCount =
    data?.currentUser?.associateTransferRequestsCount || 0

  if (associateTransferRequestsCount && associateTransferRequestsCount > 0) {
    return <Badge>{associateTransferRequestsCount}</Badge>
  } else {
    return ''
  }
}

const ImportBar = () => {
  const QUERY = gql`
    query ManagedAssociatesQuery {
      managedAssociates {
        totalAssociatesCount
        importedAssociatesCount
      }
    }
  `

  const { data } = useQuery(QUERY)

  const associatesCompleted = data?.managedAssociates?.importedAssociatesCount
  const associatesTotal = data?.managedAssociates?.totalAssociatesCount

  if (!associatesTotal || associatesCompleted === associatesTotal) {
    return null
  }

  return (
    <ImportProgressBarContainer
      onClick={() => {
        navigate(routes.associateOccurrences())
      }}
    >
      <Text color="subdued">
        {associatesCompleted} / {associatesTotal} associates completed
      </Text>
      <ProgressBar
        progress={parseInt((associatesCompleted / associatesTotal) * 100, 10)}
        animated={false}
      />
    </ImportProgressBarContainer>
  )
}

const MainLayout = ({ loading, user, children }) => {
  const { logOut } = useAuth()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const toggleShowUserMenu = () => setShowUserMenu(!showUserMenu)

  const { hasRole } = usePermissions()

  const associate = user?.associate

  const showDeveloperFeatures = true
  const isDeveloper = hasRole && hasRole('DEVELOPER') && showDeveloperFeatures

  const userMenuMarkup = !loading && (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Profile settings',
              icon: SettingsMinor,
              onAction: () => {
                navigate(routes.settings())
              },
            },
            {
              content: 'Log out',
              icon: LogOutMinor,
              onAction: logOut,
            },
          ],
        },
      ]}
      name={userDisplayString(user)}
      detail={associate?.costCenter?.name}
      initials={initials(user)}
      open={showUserMenu}
      onToggle={toggleShowUserMenu}
    />
  )

  const associateSubNavigationItems = [
    {
      url: '/associates',
      label: 'Associates',
      selected:
        !useMatch('/associates/counseling').match &&
        (useMatch('/associates').match || useMatch('/associates/{id}').match),
    },
  ]
  if (hasRole && hasRole(['MANAGER', 'ADMIN', 'ACCOUNT_ADMIN'])) {
    associateSubNavigationItems.push({
      url: '/associates/counseling',
      label: 'Counseling',
      selected: useMatch('/associates/counseling').match,
      badge: <ExpiringOccurrencesBadge />,
    })
  }
  if (hasRole && hasRole(['MANAGER', 'ADMIN', 'ACCOUNT_ADMIN'])) {
    associateSubNavigationItems.push({
      url: '/associates/transfers',
      label: 'Transfer Requests',
      selected: useMatch('/associates/transfers').match,
      badge: <AssociateTransfersBadge />,
    })
  }

  const navigationMarkup = (
    <Navigation location="/">
      <div>
        <Navigation.Section
          items={[
            {
              label: 'Associates',
              url: '/associates',
              selected:
                useMatch('/associates').match ||
                useMatch('/associates/{id}').match ||
                useMatch('/associates/counseling').match,
              icon: CustomersMinor,
              onClick: () => {
                navigate(routes.associates())
              },
              subNavigationItems: associateSubNavigationItems,
            },
            ...(hasRole && hasRole(['ADMIN', 'ACCOUNT_ADMIN'])
              ? [
                  {
                    label: 'Accounts',
                    url: '/accounts',
                    selected:
                      useMatch('/accounts').match ||
                      useMatch('/accounts/{id}').match ||
                      useMatch('/accounts/{id}/edit').match ||
                      useMatch('/cost_centers/{id}').match,
                    icon: FinancesMinor,
                    onClick: () => {
                      navigate(routes.accounts())
                    },
                  },
                  {
                    label: 'Users',
                    url: '/users',
                    selected:
                      useMatch('/users').match || useMatch('/users/{id}').match,
                    icon: PasskeyMinor,
                    onClick: () => navigate(routes.users()),
                  },
                ]
              : []),
            ...(hasRole && hasRole(['MANAGER', 'ADMIN', 'ACCOUNT_ADMIN'])
              ? [
                  {
                    label: 'Reports',
                    url: '/reports',
                    selected:
                      useMatch('/reports').match ||
                      useMatch('/reports/{id}').match,
                    icon: AnalyticsMinor,
                    onClick: () => {
                      console.log('go to reports')
                      // navigate(routes.reports())
                    },
                  },
                ]
              : []),
          ]}
        />
        {isDeveloper && (
          <Navigation.Section
            title={
              <NavigationSectionHeading>Alumworks</NavigationSectionHeading>
            }
            items={[
              {
                label: 'Onboarding',
                url: '/onboarding',
                selected:
                  useMatch('/onboarding').match ||
                  useMatch('/onboarding/occurrences').match,
                icon: CircleTickMinor,
                onClick: () => navigate(routes.onboarding()),
              },
              {
                label: 'Email',
                url: '/email',
                selected:
                  useMatch('/email').match || useMatch('/email/{id}').match,
                icon: InviteMinor,
                onClick: () => navigate(routes.email()),
              },
              {
                label: 'Print',
                url: '/print',
                selected: useMatch('/print').match,
                icon: PrintMinor,
                onClick: () => navigate(routes.print()),
              },
            ]}
          />
        )}
      </div>
      <Navigation.Section
        title={<NavigationSectionHeading>Need help?</NavigationSectionHeading>}
        items={[
          {
            label: 'Support',
            url: '/support',
            selected:
              useMatch('/support').match || useMatch('/support/{handle}').match,
            icon: QuestionMarkMinor,
          },
        ]}
      />
    </Navigation>
  )

  const _logo = {
    topBarSource: logo,
  }

  const [showMobileNavigation, setShowMobileNavigation] = useState(false)

  return (
    <Frame
      topBar={
        <TopBar
          showNavigationToggle
          onNavigationToggle={() =>
            setShowMobileNavigation(!showMobileNavigation)
          }
          userMenu={userMenuMarkup}
        />
      }
      navigation={navigationMarkup}
      logo={_logo}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={() => setShowMobileNavigation(false)}
    >
      <ImportBar />
      {loading && <Loading />}
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      <PaddedBottom>{children}</PaddedBottom>
    </Frame>
  )
}

const MainLayoutContainer = ({ skeleton, children }) => {
  const { loading, data } = useQuery(USER_QUERY, {
    onError: (error) => {
      console.log(`Access error: `, error)
      if (error.message === 'Not authorized') {
        // navigate(routes.login())
      }
    },
  })

  const user = data?.currentUser

  if (user && !user.hasCompletedOnboarding) {
    navigate(routes.onboarding())
  }

  return (
    <PermissionsProvider currentUser={user}>
      <MainLayout loading={loading || skeleton} user={user}>
        {children}
      </MainLayout>
    </PermissionsProvider>
  )
}

export default MainLayoutContainer
