// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { useAuth } from '@redwoodjs/auth'
import { Router, Route, Set } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'
import { useIdleTimer } from 'react-idle-timer'

import MainLayout from './layouts/MainLayout/MainLayout'
import OnboardingLayout from './layouts/OnboardingLayout/OnboardingLayout'
import BulkEditOccurrencesLayout from './layouts/BulkEditOccurrencesLayout/BulkEditOccurrencesLayout'
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout/UnauthenticatedLayout'

const MainLoader = () => <MainLayout skeleton />

const INACTIVE_TIMEOUT = 1000 * 60 * 30

const Routes = () => {
  const { logOut } = useAuth()

  useIdleTimer({
    timeout: INACTIVE_TIMEOUT,
    crossTab: true,
    onPresenceChange: (isIdle) => {
      if (isIdle) {
        // TODO: Custom element
        toast.error('You have been logged out due to inactivity.')
        logOut()
      }
    },
  })

  return (
    <Router>
      <Set wrap={UnauthenticatedLayout}>
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/signup" page={SignupPage} name="signup" />
        <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
        <Route path="/invite" page={UserInvitePage} name="userInvite" />
      </Set>
      <Set wrap={OnboardingLayout} private unauthenticated="login">
        <Route path="/onboarding" page={OnboardingPage} name="onboarding" />
        <Route path="/onboarding/occurrences" page={AddOccurrencesPage} name="onboardingOccurrences" />
      </Set>
      <Set wrap={BulkEditOccurrencesLayout} private unauthenticated="login">
        <Route path="/associates/occurrences" page={AddOccurrencesMainPage} name="associateOccurrences" />
      </Set>
      <Set wrap={MainLayout} private unauthenticated="login" whileLoadingAuth={MainLoader}>
        <Route path="/" redirect="/associates" />
        <Route path="/settings" page={SettingsPage} name="settings" />
        <Route path="/associates" page={AssociatesPage} name="associates" />
        <Route path="/associates/counseling" page={AssociateCounselingPage} name="associateCounseling" />
        <Route path="/associates/transfers" page={AssociateTransfersPage} name="associateTransfers" />
        <Route path="/associates/{id}" page={AssociatePage} name="associate" />
        <Route path="/accounts" page={AccountsPage} name="accounts" />
        <Route path="/accounts/new" page={NewAccountPage} name="newAccount" />
        <Route path="/accounts/{id}" page={AccountPage} name="account" />
        <Route path="/accounts/{id}/edit" page={AccountEditPage} name="accountEdit" />
        <Route path="/cost_centers/{id}" page={CostCenterPage} name="costCenter" />
        <Route path="/occurrences/add" page={AddOccurrencesPage} name="addOccurrences" />
        <Route path="/email" page={EmailPage} name="email" />
        <Route path="/email/{handle}" page={EmailPage} name="emails" />
        <Route path="/users" page={UsersPage} name="users" />
        <Route path="/users/new" page={NewUserPage} name="newUser" />
        <Route path="/users/{id}" page={UserPage} name="user" />
        <Route path="/reports" page={ReportsPage} name="reports" />
        <Route path="/print" page={PrintPage} name="print" />
        <Route path="/support" page={SupportPage} name="support" />
        <Route path="/support/{handle}" page={SupportPage} name="support" />
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
