export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const userDisplayString = (user) => {
  return (
    (user &&
      (user.associate ? user.associate.name : user.name || user.email)) ||
    ''
  )
}

export const costCenterAddressString = (costCenter) => {
  if (costCenter.address) {
    return `${costCenter.address.address1}, ${costCenter.address.city}, ${costCenter.address.state} ${costCenter.address.zip}`
  }
  return 'No address on file'
}

export const initials = (user) => {
  const str = userDisplayString(user)
  const parts = str.split(' ')
  if (parts.length === 1) {
    return str.charAt(0).toUpperCase()
  }
  if (parts.length > 1) {
    return `${parts[0].charAt(0).toUpperCase()}${parts[1]
      .charAt(0)
      .toUpperCase()}`
  }
  return null
}

export const placeholders = {
  employeeId: 'e.g. 000123456',
  firstName: 'Jane',
  lastName: 'Doe',
  name: 'Jane Doe',
  email: 'e.g. you@compass-usa.com',
  phone: 'e.g. 613-555-1010',
}
