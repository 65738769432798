import { useState } from 'react'

import { Frame, Loading, TopBar } from '@shopify/polaris'
import { LogOutMinor } from '@shopify/polaris-icons'

import { useAuth } from '@redwoodjs/auth'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { initials, userDisplayString } from 'src/helpers/strings'

import { PermissionsProvider } from '../../contexts/permissions'

import logo from './logo.png'

const USER_QUERY = gql`
  query OnboardingUserQuery {
    currentUser {
      id
      email
      roles
      phone
      externalId
      firstName
      lastName
      associate {
        id
        firstName
        lastName
        externalId
        name
        costCenters {
          id
          name
        }
      }
    }
  }
`

const OnboardingLayout = ({ loading, user, children }) => {
  const { logOut, currentUser } = useAuth()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const toggleShowUserMenu = () => setShowUserMenu(!showUserMenu)

  const associate = user?.associate

  const userMenuMarkup = !loading && (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Log out',
              icon: LogOutMinor,
              onAction: logOut,
            },
          ],
        },
      ]}
      name={userDisplayString(user)}
      detail={associate?.costCenter?.name}
      initials={initials(user)}
      open={showUserMenu}
      onToggle={toggleShowUserMenu}
    />
  )

  return (
    <Frame
      topBar={(
        <TopBar
          userMenu={userMenuMarkup}
        />
      )}
      logo={{
        topBarSource: logo,
        url: '/',
        accessibilityLabel: 'TrackPath',
      }}
    >
      {loading && <Loading />}
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      {children}
    </Frame>
  )
}

const OnboardingLayoutContainer = ({ children }) => {
  const { loading, data } = useQuery(USER_QUERY)

  const user = data?.currentUser

  if (loading) {
    return ''
  }

  return (
    <PermissionsProvider currentUser={user}>
      <OnboardingLayout loading={loading} user={user}>
        {children}
      </OnboardingLayout>
    </PermissionsProvider>
  )
}

export default OnboardingLayoutContainer
